import { defineStore } from 'pinia'
import { User } from '~/models/User'

export const useAppStore = defineStore('app', {
  state: () => ({
    _user: {} as User,
    _token: ''
  }),
  getters: {
    getUser: state => state._user,
    getToken: state => state._token,
    getEmail: state => state._user.email ? state._user.email : '',
    getCompanyId: (state) => {
      if (state._user.roles.includes('ROLE_COMPANY') && state._user.companies && state._user.companies.length > 0) {
        return state._user.companies[0].split('/')[2]
      }
      return ''
    },
    isGrantedAdmin: (state) => {
      let isGranted = false
      if (state._user.roles) {
        isGranted = state._user.roles.includes('ROLE_ADMIN')
      }
      return isGranted
    },
    isGrantedCandidate: (state) => {
      let isGranted = false
      if (state._user.roles) {
        isGranted = state._user.roles.includes('ROLE_CANDIDATE')
      }
      return isGranted
    },
    isGrantedCompany: (state) => {
      let isGranted = false
      if (state._user.roles) {
        isGranted = state._user.roles.includes('ROLE_COMPANY')
      }
      return isGranted
    },
    hasCompany: (state) => {
      let res = false
      if (state._user.roles.includes('ROLE_COMPANY') && state._user.companies && state._user.companies.length > 0) {
        res = true
      }
      return res
    }
  },
  actions: {
    setUser (user: User) {
      this._user = user
    },
    setToken (token: string) {
      this._token = token
    },
    init () {
      this._user = {} as User
      this._token = ''
    }
  },
  persist: true
})
